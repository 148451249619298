import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";

import Particle from "../../components/Particle";
import pdf from "../../assets/NihadMaharramovCV_SoftwareEngineer.pdf";
import NavbarSoftware from "../../components/Navbar/NavbarSoftware";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const resumeLink = `https://raw.githubusercontent.com/nihadishi/nihadishi/main/NihadMaharramovCV_SoftwareEngineer.pdf`;

const Resume = () => {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <>
      <NavbarSoftware />
      <div>
        <Container fluid className="resume-section">
          <Particle />
          <Row style={{ justifyContent: "center", position: "relative" }}>
            <Button
              variant="primary"
              href={pdf}
              target="_blank"
              style={{ maxWidth: "250px" }}
            >
              <AiOutlineDownload />
              &nbsp;Download Resume
            </Button>
          </Row>

          <Row className="resume">
            {width > 786 ? (
              <Document
                file={resumeLink}
                className="d-flex justify-content-center"
              >
                <Page pageNumber={1} width={width / 2.5} />
                <Page pageNumber={2} width={width / 2.5} />
                {/* <Page pageNumber={3} width={width/3.1} /> */}
              </Document>
            ) : (
              <>
                <Document
                  file={resumeLink}
                  className="d-flex justify-content-center"
                >
                  <Page pageNumber={1} width={width / 1.15} />
                </Document>
                <Document
                  file={resumeLink}
                  className="d-flex justify-content-center"
                >
                  <Page pageNumber={2} width={width / 1.15} />
                </Document>
                {/* <Document file={resumeLink} className="d-flex justify-content-center">
            <Page pageNumber={3} width={width/1.15} />
          </Document> */}
              </>
            )}
          </Row>

          <Row style={{ justifyContent: "center", position: "relative" }}>
            <Button
              variant="primary"
              href={pdf}
              target="_blank"
              style={{ maxWidth: "250px" }}
            >
              <AiOutlineDownload />
              &nbsp;Download Resume
            </Button>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Resume;
