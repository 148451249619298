import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiCplusplusbuilder,
  SiAnaconda,
  SiOpenai,
  SiBluetooth,
  SiMongodb
} from "react-icons/si";
import {
  DiVisualstudio,
  DiLinux,
  DiUbuntu,
  DiAws,
  DiNodejs,
  DiTerminal,
  DiSublime,
  DiMysql,
  DiSqllite,
  DiGit,
} from "react-icons/di";

const Toolstack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiVisualstudio />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Visual Studio</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiLinux />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Linux</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiUbuntu />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Ubuntu</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAws />
        <p style={{ textAlign: "center", fontSize: "14px" }}>AWS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Node.js</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiTerminal />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Terminal</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiSublime />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Sublime Text</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMongodb />
        <p style={{ textAlign: "center", fontSize: "14px" }}>MongoDB</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
        <p style={{ textAlign: "center", fontSize: "14px" }}>MySQL</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiSqllite />
        <p style={{ textAlign: "center", fontSize: "14px" }}>SQLite</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Git</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCplusplusbuilder />
        <p style={{ textAlign: "center", fontSize: "14px" }}>C++ Builder</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAnaconda />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Anaconda</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiOpenai />
        <p style={{ textAlign: "center", fontSize: "14px" }}>OpenAI</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBluetooth />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Bluetooth</p>
      </Col>
    </Row>
  );
};

export default Toolstack;
