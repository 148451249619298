import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

import NavbarSoftware from "./components/Navbar/NavbarSoftware";
import HomeSoftware from './pages/Software/HomeSoftware'
import ProjectSoftware from './pages/Software/ProjectsSoftware'
import ResumeSoftware from './pages/Software/ResumeSoftware'
import SkillSoftware from './pages/Software/SkillsetSoftware'
import ContactSoftware from './pages/Software/ContactSoftware'
import CertificatesSoftware from "./pages/Software/CertificatesSoftware";
import Footer from "./components/Footer/Footer";
import Preloader from "./components/PreLoader"
import ScrollToTop from "./components/ScrollToTop"

import "./App.css";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeSoftware />} />
          <Route path="/skillset" element={<SkillSoftware />} />
          {/* <Route path="/projects" element={<ProjectSoftware />} /> */}
          <Route path="/resume" element={<ResumeSoftware />} />
          <Route path="/contact" element={<ContactSoftware />} />
          <Route path="/certificate" element={<CertificatesSoftware />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
