import React from "react";
import { Container } from "react-bootstrap";

import Particle from '../../components/Particle'
import Techstack from "../../components/Skillset/Software/Techstack";
import Toolstack from "../../components/Skillset/Software/Toolstack";
import Leetcode from "../../components/Skillset/Software/Leetcode";
import Github from "../../components/Skillset/Software/Github";
import NavbarSoftware from "../../components/Navbar/NavbarSoftware"

const Skillset = () => {
  return (
    <>
    <NavbarSoftware/>
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Professional <strong className="yellow">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="yellow">Tools</strong> I use
        </h1>
        <Toolstack />
        {/* <Leetcode /> */}
        <Github />
      </Container>
    </Container>
    </>
  )
}

export default Skillset