import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LaptopImg from "../../../assets/home-main.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const About = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="yellow"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Hello, my name is <span className="yellow">Nihad Maharramov</span> 
              and I’m from <span className="yellow">Baku, Azerbaijan</span>.
              <br />
              <br />
              I am an undergraduate student in the IT Department at <b>INHA University</b>, where I specialize in low-level AI development and embedded systems programming.
              <br />
              <br />
              As an <b className="yellow">Embedded Software Engineer</b>, I am passionate about solving complex technical challenges, building efficient systems, and continuously expanding my expertise.
              <br />
              <br />
              I have in-depth experience in:
              <ul>
                <li>
                  <b className="yellow">Programming Languages:</b> C, C++, Embedded C, Python, JavaScript
                </li>
                <li>
                  <b className="yellow">Microcontroller Platforms:</b> STM32, ATmega328P
                </li>
                <li>
                  <b className="yellow">Frameworks and Tools:</b> FreeRTOS, PlatformIO, Keil, Arduino IDE
                </li>
              </ul>
              <br />
              I am passionate about developing innovative solutions using <b className="yellow">real-time systems, embedded C++, and hardware-software co-design</b>.
              <br />
              <br />
              I also enjoy building new <i className="yellow">automation, data processing, and AI-driven applications</i> that push the boundaries of modern technology.
              <br />
              <br />
              My ultimate goal is to contribute to impactful projects that blend <b className="yellow">performance, efficiency, and innovation</b> in the world of electronics and software.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={LaptopImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="yellow">connect</span> with me on the following platforms:
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/nihadishi"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="GitHub"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/nihadishi"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="Twitter"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/nihadishi/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/nihadishi"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="Instagram"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
