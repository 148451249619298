import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiPython } from "react-icons/di";
import {
  SiArduino,
  SiAdafruit,
  SiEspressif,
  SiArm,
  SiStmicroelectronics,
  SiArmkeil,
  SiIntel,
  SiZigbee,
  SiBroadcom,
  SiBabel,
  SiOpensourcehardware,
  SiRaspberrypi,
  SiCplusplus,
  SiMicropython,
  SiDrone,
  SiEsphome
} from "react-icons/si";

const Techstack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
       <Col xs={4} md={2} className="tech-icons">
        <SiCplusplus />
        <p style={{ textAlign: "center", fontSize: "14px" }}>C++</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Python</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicropython />
        <p style={{ textAlign: "center", fontSize: "14px" }}>MicroPython</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRaspberrypi />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Raspberry Pi</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdafruit />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Adafruit</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiStmicroelectronics />
        <p style={{ textAlign: "center", fontSize: "14px" }}>STM</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiArduino />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Arduino</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiEsphome />
        <p style={{ textAlign: "center", fontSize: "14px" }}>ESP </p>
      </Col>   
      <Col xs={4} md={2} className="tech-icons">
        <SiArm />
        <p style={{ textAlign: "center", fontSize: "14px" }}>ARM MCU</p>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <SiIntel />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Intel MCU</p>
      </Col>     
        
      <Col xs={4} md={2} className="tech-icons">
        <SiEspressif />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Espressif</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiArmkeil />
        <p style={{ textAlign: "center", fontSize: "14px" }}>ARM Keil</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBroadcom />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Broadcom</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBabel />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Babel Config</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiZigbee />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Zigbee</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiOpensourcehardware />
        <p style={{ textAlign: "center", fontSize: "14px" }}>Open Source HW</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDrone />
        <p style={{ textAlign: "center", fontSize: "14px" }}>UAV</p>
      </Col>
    </Row>
  );
};

export default Techstack;
